var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-ryProbe-afterSale-list" },
    [
      _c("Nav"),
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "title" }, [_vm._v("返厂维护记录")]),
        _c("div", { staticClass: "header-right" }, [
          _c("span", { staticClass: "btn", on: { click: _vm.switchAccount } }, [
            _vm._v("切换登录")
          ]),
          !_vm.isShowSureBtn
            ? _c(
                "span",
                {
                  staticClass: "btn add-record",
                  on: { click: _vm.handleClick }
                },
                [_vm._v("新建记录")]
              )
            : _vm._e()
        ])
      ]),
      _c("div", { class: { content: true, "max-height": true } }, [
        _c(
          "div",
          { staticClass: "filter-box" },
          [
            _c("el-row", { attrs: { gutter: 24 } }, [
              _c("div", { staticClass: "header-tab" }, [
                _c(
                  "span",
                  {
                    class: { "tab-item": true, active: _vm.status == 0 },
                    on: {
                      click: function($event) {
                        return _vm.onchangeTab(0)
                      }
                    }
                  },
                  [_vm._v("待确认")]
                ),
                _c(
                  "span",
                  {
                    class: { "tab-item": true, active: _vm.status == 1 },
                    on: {
                      click: function($event) {
                        return _vm.onchangeTab(1)
                      }
                    }
                  },
                  [_vm._v("待复核")]
                ),
                _c(
                  "span",
                  {
                    class: { "tab-item": true, active: _vm.status == 2 },
                    on: {
                      click: function($event) {
                        return _vm.onchangeTab(2)
                      }
                    }
                  },
                  [_vm._v("已确认")]
                ),
                _c(
                  "span",
                  {
                    class: { "tab-item": true, active: _vm.status == 4 },
                    on: {
                      click: function($event) {
                        return _vm.onchangeTab(4)
                      }
                    }
                  },
                  [_vm._v("已完成")]
                ),
                _c(
                  "span",
                  {
                    class: { "tab-item": true, active: _vm.status == 3 },
                    on: {
                      click: function($event) {
                        return _vm.onchangeTab(3)
                      }
                    }
                  },
                  [_vm._v("已结算")]
                ),
                _c("div", { staticClass: "right-btn" }, [
                  _vm.isShowSureBtn && _vm.status == 4
                    ? _c(
                        "div",
                        {
                          staticClass: "warning-probe-btn",
                          on: {
                            click: function($event) {
                              _vm.isShowDownloadModal = true
                            }
                          }
                        },
                        [_vm._v("导出数据")]
                      )
                    : _vm._e(),
                  _vm.isShowSureBtn && (_vm.status == 2 || _vm.status == 1)
                    ? _c(
                        "div",
                        {
                          staticClass: "warning-probe-btn",
                          on: {
                            click: function($event) {
                              return _vm.openUploadModal(1)
                            }
                          }
                        },
                        [_vm._v("出厂核对")]
                      )
                    : _vm._e(),
                  _vm.isShowSureBtn && _vm.status == 3
                    ? _c(
                        "div",
                        {
                          staticClass: "warning-probe-btn",
                          on: {
                            click: function($event) {
                              return _vm.openUploadModal(0)
                            }
                          }
                        },
                        [_vm._v("结算归档")]
                      )
                    : _vm._e()
                ])
              ])
            ]),
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-form",
                  { attrs: { "label-width": "90px", center: "" } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "pl-10",
                        attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "责任方:", "label-width": "60px" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: {
                                  change: function($event) {
                                    return _vm.loadCheckList(1)
                                  }
                                },
                                model: {
                                  value: _vm.responsiblePartyId,
                                  callback: function($$v) {
                                    _vm.responsiblePartyId = $$v
                                  },
                                  expression: "responsiblePartyId"
                                }
                              },
                              _vm._l(_vm.responsiblePartyList, function(item) {
                                return _c("el-option", {
                                  key: item.val,
                                  attrs: { label: item.text, value: item.val }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    !_vm.status
                      ? _c(
                          "el-col",
                          {
                            staticClass: "pl-10",
                            attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "返厂原因:",
                                  "label-width": "72px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: {
                                      change: function($event) {
                                        return _vm.loadCheckList(1)
                                      }
                                    },
                                    model: {
                                      value: _vm.warningType,
                                      callback: function($$v) {
                                        _vm.warningType = $$v
                                      },
                                      expression: "warningType"
                                    }
                                  },
                                  _vm._l(_vm.tagList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.tagName,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.status
                      ? _c(
                          "el-col",
                          {
                            staticClass: "pl-10",
                            attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "检查结果:",
                                  "label-width": "72px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: {
                                      change: function($event) {
                                        return _vm.loadCheckList(1)
                                      }
                                    },
                                    model: {
                                      value: _vm.resultTagId,
                                      callback: function($$v) {
                                        _vm.resultTagId = $$v
                                      },
                                      expression: "resultTagId"
                                    }
                                  },
                                  _vm._l(_vm.resultTagList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.tagName,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { xs: 10, sm: 10, md: 9, lg: 9, xl: 9 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间:", "label-width": "72px" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "startTime" } },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "开始时间"
                                      },
                                      on: { change: _vm.loadCheckList },
                                      model: {
                                        value: _vm.startTime,
                                        callback: function($$v) {
                                          _vm.startTime = $$v
                                        },
                                        expression: "startTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 1 } },
                              [_vm._v("-")]
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "endTime" } },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "结束时间"
                                      },
                                      on: { change: _vm.loadCheckList },
                                      model: {
                                        value: _vm.endTime,
                                        callback: function($$v) {
                                          _vm.endTime = $$v
                                        },
                                        expression: "endTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "pl-10",
                        attrs: { xs: 6, sm: 6, md: 6, lg: 7, xl: 7 }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "探头ID:",
                              prop: "number",
                              "label-width": "72px"
                            }
                          },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "请输入探头ID" },
                                on: {
                                  input: function($event) {
                                    return _vm.inputChange("search")
                                  }
                                },
                                model: {
                                  value: _vm.number,
                                  callback: function($$v) {
                                    _vm.number = $$v
                                  },
                                  expression: "number"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _c(
                                    "span",
                                    {
                                      attrs: { loading: _vm.loading },
                                      on: { click: _vm.search }
                                    },
                                    [_vm._v("搜索")]
                                  )
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.status == 4
                      ? _c(
                          "el-col",
                          {
                            staticClass: "pl-10 pos-filter-item",
                            attrs: { xs: 5, sm: 5, md: 6, lg: 7, xl: 7 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "",
                                  prop: "number",
                                  "label-width": "0"
                                }
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    on: { change: _vm.loadCheckList },
                                    model: {
                                      value: _vm.scrap,
                                      callback: function($$v) {
                                        _vm.scrap = $$v
                                      },
                                      expression: "scrap"
                                    }
                                  },
                                  [_vm._v("报废设备")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { class: { "table-body": true } },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.status,
                    expression: "!status"
                  }
                ],
                staticClass: "table-box"
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.list, stripe: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c(
                                "el-form",
                                {
                                  staticClass: "demo-table-expand",
                                  attrs: {
                                    "label-position": "left",
                                    inline: ""
                                  }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "备注：" } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(" " + props.row.remark))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "探头 ID", prop: "deviceId" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "返厂原因" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.tagNames &&
                              scope.row.tagNames.length > 1
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.tagNames.join("、"))
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.tagNames
                                          ? scope.row.tagNames[0]
                                          : "-"
                                      )
                                    )
                                  ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "返厂备注" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              !scope.row.status
                                ? _c("div", [_vm._v(_vm._s(scope.row.remark))])
                                : _c("div", [
                                    scope.row.tagNames &&
                                    scope.row.tagNames.length > 1
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.tagNames.join("、")
                                            )
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.tagNames
                                                ? scope.row.tagNames[0]
                                                : "-"
                                            )
                                          )
                                        ])
                                  ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: _vm.timeText, prop: "time" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewInfo(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              ),
                              !_vm.status && !_vm.isShowSureBtn
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("开始质检")]
                                  )
                                : _vm._e(),
                              _vm.isShowSureBtn && _vm.status == 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(scope.row, 2)
                                        }
                                      }
                                    },
                                    [_vm._v("修改 ")]
                                  )
                                : _vm._e(),
                              _vm.isShowSureBtn && _vm.status == 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "success", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(scope.row, 1)
                                        }
                                      }
                                    },
                                    [_vm._v("同意 ")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.status == 1 || _vm.status == 2 || _vm.status == 4,
                    expression: "status == 1 || status == 2 || status == 4"
                  }
                ],
                staticClass: "table-box"
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.list, stripe: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c(
                                "el-form",
                                {
                                  staticClass: "demo-table-expand",
                                  attrs: {
                                    "label-position": "left",
                                    inline: ""
                                  }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "备注：" } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(" " + props.row.remark))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "探头 ID",
                        width: "100",
                        prop: "deviceId"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "质检结果" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              !scope.row.status
                                ? _c("div", [_vm._v(_vm._s(scope.row.remark))])
                                : _c("div", [
                                    scope.row.tagNames &&
                                    scope.row.tagNames.length > 1
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.tagNames.join("、")
                                            )
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.tagNames
                                                ? scope.row.tagNames[0]
                                                : "-"
                                            )
                                          )
                                        ])
                                  ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "责任方",
                        prop: "responsiblePartyText"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "处理方式",
                        prop: "handlingMethodFinalText"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { label: _vm.timeText, prop: "time" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewInfo(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              ),
                              !_vm.status && !_vm.isShowSureBtn
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("开始质检")]
                                  )
                                : _vm._e(),
                              _vm.isShowSureBtn && _vm.status == 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(scope.row, 2)
                                        }
                                      }
                                    },
                                    [_vm._v("修改 ")]
                                  )
                                : _vm._e(),
                              _vm.isShowSureBtn && _vm.status == 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "success", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(scope.row, 1)
                                        }
                                      }
                                    },
                                    [_vm._v("同意 ")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.status == 3,
                    expression: "status == 3"
                  }
                ],
                staticClass: "table-box"
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.list, stripe: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c(
                                "el-form",
                                {
                                  staticClass: "demo-table-expand",
                                  attrs: {
                                    "label-position": "left",
                                    inline: ""
                                  }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "备注：" } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(" " + props.row.remark))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "探头 ID",
                        width: "100",
                        prop: "deviceId"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        label: "责任方",
                        prop: "responsiblePartyText"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "处理方式",
                        prop: "handlingMethodFinalText"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { label: _vm.timeText, prop: "time" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewInfo(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              ),
                              !_vm.status && !_vm.isShowSureBtn
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("开始质检")]
                                  )
                                : _vm._e(),
                              _vm.isShowSureBtn && _vm.status == 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(scope.row, 2)
                                        }
                                      }
                                    },
                                    [_vm._v("修改 ")]
                                  )
                                : _vm._e(),
                              _vm.isShowSureBtn && _vm.status == 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "success", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(scope.row, 1)
                                        }
                                      }
                                    },
                                    [_vm._v("同意 ")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("el-pagination", {
              attrs: {
                "page-no": _vm.pageOptions.pageNo,
                "current-page": _vm.pageOptions.pageNo,
                "page-sizes": _vm.pageOptions.pageSizes,
                "page-size": _vm.pageOptions.pageSize,
                center: "",
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.pageOptions.total
              },
              on: {
                "size-change": _vm.onChangePageSize,
                "current-change": _vm.onChangePageNo
              }
            })
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择需要确认的订单责任方",
            visible: _vm.isShowSureModal,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowSureModal = $event
            },
            close: _vm.onCancel
          }
        },
        [
          _c("el-form", { attrs: { model: _vm.form } }, [
            _c(
              "div",
              {
                class: {
                  "select-model-item": true,
                  active: _vm.sureResponsibleModel == 5
                },
                on: {
                  click: function($event) {
                    return _vm.onclickSelect(5)
                  }
                }
              },
              [_vm._v("览宋")]
            ),
            _c(
              "div",
              {
                class: {
                  "select-model-item": true,
                  active: _vm.sureResponsibleModel == 4
                },
                on: {
                  click: function($event) {
                    return _vm.onclickSelect(4)
                  }
                }
              },
              [_vm._v("英沃")]
            ),
            _c("div", { staticClass: "modal-tip" }, [
              _vm._v("*点击“确定”后，对应责任方的维护订单将变为“已确认”状态")
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading },
                  on: {
                    click: function($event) {
                      _vm.isShowSureModal = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !_vm.sureResponsibleModel,
                    loading: _vm.loading
                  },
                  on: { click: _vm.onConfirmSelect }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择需要导出的时间",
            visible: _vm.isShowDownloadModal,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowDownloadModal = $event
            },
            close: _vm.onCancel
          }
        },
        [
          _c(
            "el-form",
            {
              staticStyle: {
                width: "70%",
                margin: "20px auto",
                "min-height": "50px"
              }
            },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "startTime" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "date", placeholder: "开始时间" },
                            on: { change: _vm.loadCheckList },
                            model: {
                              value: _vm.startDownloadTime,
                              callback: function($$v) {
                                _vm.startDownloadTime = $$v
                              },
                              expression: "startDownloadTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "line line-two", attrs: { span: 2 } },
                    [_vm._v("-")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "endTime" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "date", placeholder: "结束时间" },
                            on: { change: _vm.loadCheckList },
                            model: {
                              value: _vm.endDownloadTime,
                              callback: function($$v) {
                                _vm.endDownloadTime = $$v
                              },
                              expression: "endDownloadTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading },
                  on: {
                    click: function($event) {
                      _vm.isShowDownloadModal = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !(_vm.startDownloadTime && _vm.endDownloadTime),
                    loading: _vm.loading
                  },
                  on: { click: _vm.onclickDownloadBtn }
                },
                [_vm._v("确认导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传表格",
            visible: _vm.isShowUploadModal,
            center: "",
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowUploadModal = $event
            },
            close: _vm.modalClose
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.modalStep == 1,
                  expression: "modalStep == 1"
                }
              ],
              staticClass: "dialog-body"
            },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    "on-change": _vm.onChange,
                    "before-remove": _vm.handelRemove,
                    action: "",
                    limit: 1,
                    accept: ".xls,.xlsx",
                    "auto-upload": false,
                    "list-type": "file",
                    "file-list": _vm.fileList
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _c("em", [_vm._v("点击上传")])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("只能上传xls/xlsx文件")]
                  )
                ]
              )
            ],
            1
          ),
          _vm.modalStep == 2
            ? _c("div", { staticClass: "dialog-body" }, [
                _vm.settlementData.successCount == _vm.settlementData.total
                  ? _c("div", { staticClass: "success-res" }, [
                      _c("div", { staticClass: "el-icon-success" }),
                      _c("div", { staticClass: "success-title" }, [
                        _vm._v("归档成功")
                      ]),
                      _c("div", { staticClass: "success-tip" }, [
                        _vm._v(
                          "本次成功归档" +
                            _vm._s(_vm.settlementData.successCount) +
                            "个返厂订单"
                        )
                      ])
                    ])
                  : _c("div", { staticClass: "fail-res" }, [
                      _c("div", { staticClass: "fail-tip" }, [
                        _vm._v(
                          "本次归档总共" +
                            _vm._s(_vm.settlementData.total) +
                            "条订单。成功" +
                            _vm._s(
                              _vm.settlementData.successCount +
                                _vm.settlementData.succeedCount
                            ) +
                            "单(含" +
                            _vm._s(_vm.settlementData.succeedCount) +
                            "条之前已结算)，"
                        ),
                        _c("span", [
                          _vm._v(
                            "失败" +
                              _vm._s(_vm.settlementData.failureSize) +
                              "条"
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "fail-table" },
                        [
                          _c("div", { staticClass: "table-header" }, [
                            _c("div", { staticClass: "left" }, [
                              _vm._v("设备编码")
                            ]),
                            _c("div", { staticClass: "right" }, [
                              _vm._v("失败原因")
                            ])
                          ]),
                          _vm._l(_vm.settlementData.failureReason, function(
                            item
                          ) {
                            return _c(
                              "div",
                              { key: item.first, staticClass: "list-item" },
                              [
                                _c("div", { staticClass: "left" }, [
                                  _vm._v(_vm._s(item.first))
                                ]),
                                _c("div", { staticClass: "right" }, [
                                  _vm._v(_vm._s(item.second))
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
              ])
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.modalStep == 1
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.isShowUploadModal = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  )
                : _vm._e(),
              _vm.modalStep == 2
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.isShowUploadModal = false
                        }
                      }
                    },
                    [_vm._v("关 闭")]
                  )
                : _vm._e(),
              _vm.modalStep == 1
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading,
                        disabled: !_vm.fileList.length
                      },
                      on: { click: _vm.onSure }
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "出厂核对",
            visible: _vm.isShowCheckProbeModal,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "80%",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowCheckProbeModal = $event
            },
            close: _vm.onCancel
          }
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "el-tab-checkStatus",
              on: { "tab-click": _vm.onCheckProbeFilteTab },
              model: {
                value: _vm.checkStatus,
                callback: function($$v) {
                  _vm.checkStatus = $$v
                },
                expression: "checkStatus"
              }
            },
            [
              _c("el-tab-pane", {
                attrs: { name: "1" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("el-badge", { attrs: { value: _vm.tabsSize[1] } }, [
                          _c("span", [_vm._v("待复核")])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("el-tab-pane", {
                attrs: { name: "2" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("el-badge", { attrs: { value: _vm.tabsSize[2] } }, [
                          _c("span", [_vm._v("已确认")])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("el-tab-pane", {
                attrs: { name: "-1" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("el-badge", { attrs: { value: _vm.tabsSize[0] } }, [
                          _c("span", [_vm._v("异常订单")])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _vm.checkList.length
            ? _c("el-form", [
                _vm.checkList[0].status == 1 || _vm.checkList[0].status == 2
                  ? _c(
                      "div",
                      { staticClass: "check-list-box" },
                      _vm._l(_vm.checkList, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: item.deviceId,
                            staticClass: "check-status-item"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "item-header" },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(item.deviceId)
                                  }
                                }),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "pull-right",
                                    attrs: { type: "success", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickUpdateOrderStatus(
                                          1,
                                          item,
                                          index
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("允许出厂")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "pull-right",
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickUpdateOrderStatus(
                                          0,
                                          item,
                                          index
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("报废")]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "item-desc" }, [
                              _c("span", [
                                _vm._v(
                                  "责任方：" +
                                    _vm._s(
                                      item.responsibleParty == 5
                                        ? "览宋"
                                        : "英沃"
                                    )
                                )
                              ]),
                              _c("span", [
                                _vm._v(
                                  "处理方式：" +
                                    _vm._s(
                                      _vm._f("filterHandlingMethod")(
                                        item.handlingMethod
                                      )
                                    )
                                )
                              ]),
                              item.tagNames && item.tagNames.length
                                ? _c("span", [
                                    _vm._v(
                                      "质检结果：" +
                                        _vm._s(item.tagNames.join("、"))
                                    )
                                  ])
                                : _c("span", [_vm._v("质检结果：-")]),
                              _c("span", [
                                _vm._v(
                                  "故障描述：" + _vm._s(item.remark || "暂无")
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "item-info" }, [
                              _c(
                                "div",
                                { staticClass: "title" },
                                [
                                  _vm._v("返厂信息 "),
                                  !item.cliabData && item.hasAfterSale
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "pull-right",
                                          attrs: {
                                            type: "primary",
                                            loading: item.loading,
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onclickLoadProbeInfo(
                                                item,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("展开")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              item.cliabData && item.hasAfterSale
                                ? _c("div", { staticClass: "cliab-box" }, [
                                    _c("div", { staticClass: "device-info" }, [
                                      _c("span", [
                                        _vm._v(
                                          "返厂原因：" +
                                            _vm._s(
                                              item.cliabData
                                                ? item.cliabData.preTagName
                                                : "-"
                                            )
                                        )
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          "故障描述：" +
                                            _vm._s(
                                              item.cliabData
                                                ? item.cliabData.preRemark
                                                : "-"
                                            )
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "device-info" }, [
                                      _c("span", [_vm._v("故障曲线：")])
                                    ]),
                                    item.cliabData &&
                                    item.cliabData.images &&
                                    item.cliabData.images.length
                                      ? _c(
                                          "div",
                                          { staticClass: "item-imgs" },
                                          _vm._l(
                                            item.cliabData.images,
                                            function(img) {
                                              return _c(
                                                "a",
                                                {
                                                  key: img,
                                                  attrs: {
                                                    href: img,
                                                    target: "_blank",
                                                    alt: img
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: { src: img }
                                                  })
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "item-imgs" },
                                          [_vm._v("暂无")]
                                        )
                                  ])
                                : _vm._e(),
                              !item.hasAfterSale
                                ? _c("div", { staticClass: "empty-check" }, [
                                    _vm._v("暂无返厂信息")
                                  ])
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "item-info" }, [
                              _c(
                                "div",
                                { staticClass: "title" },
                                [
                                  _vm._v("详细信息 "),
                                  !item.cliabData
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "pull-right",
                                          attrs: {
                                            type: "primary",
                                            loading: item.loading,
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onclickLoadProbeInfo(
                                                item,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("展开")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              item.cliabData
                                ? _c("div", { staticClass: "cliab-box" }, [
                                    _c("div", { staticClass: "device-info" }, [
                                      _c("span", [
                                        _vm._v(
                                          "设备编号：" +
                                            _vm._s(item.uniqueId || "-")
                                        )
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          "开始使用：" +
                                            _vm._s(
                                              _vm._f("getYMD")(
                                                item.cliabData.startUserAt
                                              )
                                            )
                                        )
                                      ]),
                                      item.createParty == 5
                                        ? _c("span", [
                                            _vm._v(
                                              "开通维护：" +
                                                _vm._s(
                                                  _vm._f("getYMD")(
                                                    item.createAt
                                                  )
                                                )
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    item.cliabData.leaveFacQc
                                      ? _c(
                                          "div",
                                          { staticClass: "init-check" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "出厂日期：" +
                                                  _vm._s(
                                                    _vm._f("getYMD")(
                                                      item.cliabData.leaveFacQc
                                                        .updateAt
                                                    )
                                                  )
                                              )
                                            ]),
                                            _c("span", [
                                              _vm._v(
                                                "出厂AD：" +
                                                  _vm._s(
                                                    item.cliabData.leaveFacQc
                                                      .initSaturationOxy
                                                  )
                                              )
                                            ]),
                                            _c("span", [
                                              _vm._v(
                                                "标定温度：" +
                                                  _vm._s(
                                                    item.cliabData.leaveFacQc
                                                      .initSaturationOxyTemp
                                                  )
                                              )
                                            ]),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.viewCalibInfo(
                                                      item.cliabData.leaveFacQc
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("查看")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !item.cliabData.leaveFacQc
                                      ? _c(
                                          "div",
                                          { staticClass: "empty-check" },
                                          [_vm._v("暂无出厂记录")]
                                        )
                                      : _vm._e(),
                                    item.cliabData.latestFacQc
                                      ? _c(
                                          "div",
                                          { staticClass: "last-check" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "最新质检：" +
                                                  _vm._s(
                                                    _vm._f("getYMD")(
                                                      item.cliabData.latestFacQc
                                                        .updateAt
                                                    )
                                                  )
                                              )
                                            ]),
                                            _c("span", [
                                              _vm._v(
                                                "出厂AD：" +
                                                  _vm._s(
                                                    item.cliabData.latestFacQc
                                                      .initSaturationOxy
                                                  )
                                              )
                                            ]),
                                            _c("span", [
                                              _vm._v(
                                                "标定温度：" +
                                                  _vm._s(
                                                    item.cliabData.latestFacQc
                                                      .initSaturationOxyTemp
                                                  )
                                              )
                                            ]),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.viewCalibInfo(
                                                      item.cliabData.latestFacQc
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("查看")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !item.cliabData.latestFacQc
                                      ? _c(
                                          "div",
                                          { staticClass: "empty-check" },
                                          [_vm._v("暂无返厂质检记录")]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      { staticClass: "check-list-box" },
                      _vm._l(_vm.checkList, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.deviceId,
                            staticClass: "check-status-empty"
                          },
                          [
                            _c("span", {
                              domProps: { textContent: _vm._s(item.deviceId) }
                            }),
                            !(
                              Number(item.status + "") > -1 &&
                              Number(item.status + "") < 3
                            )
                              ? _c("span", [
                                  _vm._v(
                                    "未在“待确认”，“待复核”和“已确认”中找到该探头的订单"
                                  )
                                ])
                              : _c("span", [_vm._v("待英沃确认")])
                          ]
                        )
                      }),
                      0
                    )
              ])
            : _c("div", [_vm._v("暂无记录")]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading },
                  on: {
                    click: function($event) {
                      _vm.isShowCheckProbeModal = false
                    }
                  }
                },
                [_vm._v("关闭")]
              ),
              _vm.checkStatus == 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          _vm.isShowOutFactoryBatch = true
                        }
                      }
                    },
                    [_vm._v("批量出厂")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "出厂质检项",
            visible: _vm.isShowCalibModal,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowCalibModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "info-content" },
            [
              _c("div", { staticClass: "info-content-title" }, [
                _vm._v("出厂质检项：")
              ]),
              _vm._l(_vm.failArr, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: { "info-content-item": true, pass: item.state }
                  },
                  [
                    !item.state
                      ? _c("Icon", { attrs: { name: "clear" } })
                      : _c("Icon", { attrs: { name: "checked" } }),
                    _vm._v(" " + _vm._s(item.msg) + " "),
                    item.data
                      ? _c("span", [_vm._v(_vm._s(item.data))])
                      : _vm._e()
                  ],
                  1
                )
              }),
              _vm._l(_vm.passArr, function(item) {
                return _c(
                  "div",
                  {
                    key: item.data,
                    class: { "info-content-item": true, pass: item.state }
                  },
                  [
                    !item.state
                      ? _c("Icon", { attrs: { name: "clear" } })
                      : _c("Icon", { attrs: { name: "checked" } }),
                    _vm._v(" " + _vm._s(item.msg) + " "),
                    item.data
                      ? _c("span", [_vm._v(_vm._s(item.data))])
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.isShowCalibModal = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请确认责任方和处理方式",
            visible: _vm.isShowLatestReason,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowLatestReason = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "责任方" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.onchangeLastRp },
                      model: {
                        value: _vm.latestData.responsibleParty,
                        callback: function($$v) {
                          _vm.$set(_vm.latestData, "responsibleParty", $$v)
                        },
                        expression: "latestData.responsibleParty"
                      }
                    },
                    _vm._l(_vm.responsiblePartyList, function(item) {
                      return _c("el-option", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.val,
                            expression: "item.val"
                          }
                        ],
                        key: item.val,
                        attrs: { label: item.text, value: item.val }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "处理方法" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "auto" },
                    attrs: { inline: true, disabled: true },
                    model: {
                      value: _vm.latestData.handlingMethod,
                      callback: function($$v) {
                        _vm.$set(_vm.latestData, "handlingMethod", $$v)
                      },
                      expression: "latestData.handlingMethod"
                    }
                  })
                ],
                1
              ),
              !_vm.latestData.updateOrderType
                ? _c(
                    "div",
                    {
                      staticClass: "modal-tip",
                      staticStyle: { color: "#F1952F" }
                    },
                    [_vm._v("*确认报废后订单状态将改为“已完成”,不得再修改")]
                  )
                : _vm._e(),
              _vm.latestData.updateOrderType
                ? _c(
                    "div",
                    {
                      staticClass: "modal-tip",
                      staticStyle: { color: "#F1952F" }
                    },
                    [_vm._v("*允许出厂后订单状态将改为“已完成”,不得再修改")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.isShowLatestReason = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type:
                      _vm.latestData.handlingMethod == "报废"
                        ? "danger"
                        : "primary",
                    loading: _vm.subLatestReasonLoading
                  },
                  on: { click: _vm.onClickLatestCheck }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.latestData.handlingMethod == "报废" ? "报废" : "确定"
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "是否确认?",
            visible: _vm.isShowOutFactoryBatch,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowOutFactoryBatch = $event
            }
          }
        },
        [
          _c("div", { staticClass: "info-content-title" }, [
            _vm._v(
              "已确认状态下剩余" +
                _vm._s(_vm.tabsSize[2]) +
                "条订单,是否全部允许出厂?"
            )
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      ;(_vm.isShowOutFactoryBatch = false),
                        (_vm.outFactoryBatchLoading = false)
                    }
                  }
                },
                [_vm._v("关闭")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.outFactoryBatchLoading
                  },
                  on: { click: _vm.onClickOutFactoryBatch }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "搜索结果",
            visible: _vm.isShowSearchModal,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowSearchModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "info-content-title" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.searchProbeList }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "订单状态", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("filterProbeOrderStatus")(
                                    scope.row.status
                                  )
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "deviceId", label: "探头ID", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "创建时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("getYMD")(scope.row.createAt))
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.viewInfo(scope.row)
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.isShowSearchModal = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      ),
      _c("UpdateProbeCheckModal", {
        ref: "updateProbeCheckModal",
        on: { change: _vm.loadCheckList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }