require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.join");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.splice");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.iterator");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.for-each");

require("core-js/modules/web.dom-collections.iterator");

require("core-js/modules/web.url");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/dialog/style", "vant/es/dialog", "vant/es/toast/style", "vant/es/toast", "vant/es/icon/style", "vant/es/icon", "@/utils/time", "@/utils/str", "@/components/nav", "@/modal/ryProbe/updateProbeCheckModal"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/icon/style"), require("vant/es/icon"), require("@/utils/time"), require("@/utils/str"), require("@/components/nav"), require("@/modal/ryProbe/updateProbeCheckModal"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.dialog, global.style, global.toast, global.style, global.icon, global.time, global.str, global.nav, global.updateProbeCheckModal);
    global.list = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _dialog, _style2, _toast, _style3, _icon, _time, _str, _nav, _updateProbeCheckModal) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _dialog = _interopRequireDefault(_dialog);
  _toast = _interopRequireDefault(_toast);
  _icon = _interopRequireDefault(_icon);
  _nav = _interopRequireDefault(_nav);
  _updateProbeCheckModal = _interopRequireDefault(_updateProbeCheckModal);
  var _default = {
    name: "RyProbeAfterSaleList",
    components: {
      Nav: _nav.default,
      Icon: _icon.default,
      UpdateProbeCheckModal: _updateProbeCheckModal.default
    },
    filters: {
      getYMD: _time.getYMD,
      filterProbeOrderStatus: _str.filterProbeOrderStatus,
      filterHandlingMethod: _str.filterHandlingMethod
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      var responsiblePartyList = [{
        text: '全部',
        val: ''
      }, {
        text: '览宋',
        val: 5
      }, {
        text: '英沃',
        val: 4
      }];
      var pageNo = localStorage.getItem('ryProbeAfterSaleListPageNo') || 1;
      return {
        ossHost: ossHost,
        warningType: '',
        startTime: '',
        endTime: '',
        number: '',
        list: [],
        tagList: [],
        preTagList: [],
        resultTagList: [],
        isShowModal: false,
        formLabelWidth: '80px',
        editItem: {},
        isShowUploadModal: false,
        modalStep: 1,
        pageOptions: {
          pageSizes: [10, 20, 50, 100],
          pageSize: 10,
          total: 0,
          pageNo: Number(pageNo)
        },
        form: {
          probeId: '',
          tagId: 1,
          remark: '',
          responsibleParty: ''
        },
        isConfirmPass: false,
        singleItem: {},
        loading: false,
        probeBatchList: [],
        probeBatch: '全部',
        status: 0,
        responsiblePartyList: responsiblePartyList,
        responsiblePartyId: '',
        resultTagId: '',
        isShowSureModal: false,
        sureResponsibleModel: '',
        isShowDownloadModal: false,
        startDownloadTime: '',
        endDownloadTime: '',
        createResponsiblePartyObj: {},
        settlementData: {},
        fileList: [],
        responsiblePartyFinalText: '',
        checkList: [],
        // 1:待复核，2:已确认，-1:异常订单
        checkStatus: '1',
        // 是否报废
        scrap: false,
        // 依次记录数量 index从0开始
        tabsSize: [0, 0, 0],
        isShowCheckProbeModal: false,
        isShowCalibModal: false,
        isShowOutFactoryBatch: false,
        passArr: [],
        failArr: [],
        // 最终确认报废或者出厂
        latestData: {},
        isShowLatestReason: false,
        subLatestReasonLoading: false,
        outFactoryBatchLoading: false,
        searchProbeList: [],
        isShowSearchModal: false,
        timeText: '',
        name: ''
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid');
      var warningStatusTabIndex = localStorage.getItem('warningStatusTabIndex');
      var fType = vm.$strTool.filterFactoryType(uuid);
      vm.name = name;

      if (vm.$config.base.fList.indexOf(fType) > -1) {
        (fType != 'yingwo' || !name) && vm.$router.replace({
          path: "/ryProbe/afterSale/login"
        });
      } else {
        vm.$router.replace({
          path: "404"
        });
      }

      vm.pageNo = 1;
      vm.pageSize = 10;
      vm.status = Number(warningStatusTabIndex);
      vm.loadTag();
      vm.loadTag(true);
      vm.loadprobeBatchList();
    },
    computed: {
      isShowSureBtn: function isShowSureBtn() {
        var vm = this;
        var USER_NAME = vm.$config.keys.USER_NAME;
        var name = vm.$localStorage.getItem(USER_NAME);
        return name == 'Yudada';
      }
    },
    methods: {
      loadTag: function loadTag(isResult) {
        var vm = this;
        var params = {
          type: 10
        };
        !isResult ? params.type = 6 : params.handlingType = 1;
        vm.$http({
          type: "get",
          // url: `${vm.$config.base.PROBE_BASE_URL}${isResult ? 'fc/careInfo/tags' : 'returnFac/tags'}`,
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/tags"),
          params: params
        }).then(function (res) {
          if (isResult) {
            vm.resultTagList = [{
              id: '',
              tagName: "全部"
            }].concat(res.data);
          } else {
            vm.tagList = [{
              id: '',
              tagName: "全部"
            }].concat(res.data);
            vm.preTagList = res.data;
          }

          vm.loadCheckList();
        });
      },
      loadprobeBatchList: function loadprobeBatchList() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/probeBatch")
        }).then(function (res) {
          var plist = [];
          res.data.map(function (item) {
            plist.push(item.probeBatch);
          });
          vm.probeBatchList = ['全部'].concat(plist);
        });
      },
      handelRemove: function handelRemove() {
        this.fileList = [];
      },
      // 确认报废或者翻新出厂
      onClickLatestCheck: function onClickLatestCheck() {
        var vm = this;
        var params = Object.assign({}, vm.latestData);
        params.handlingMethod = params.handlingMethod == '报废' ? 2 : 3;
        vm.subLatestReasonLoading = true;
        vm.$http({
          type: 'post',
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "probeFacCare/").concat(params.id, "/manualAudit"),
          data: params
        }).then(function (res) {
          if (res.data) {
            var dIndex = -1;
            vm.checkProbeMetaData.map(function (cItem, index) {
              cItem.id == vm.checkList[vm.selectIndex].id && (dIndex = index);
            });
            vm.subLatestReasonLoading = false;
            vm.isShowLatestReason = false;
            vm.checkList.splice(vm.selectIndex, 1);
            dIndex > -1 && vm.checkProbeMetaData.splice(dIndex, 1);
            vm.filterCheckListArr();

            _toast.default.success({
              message: '操作成功'
            });
          }
        }).catch(function (err) {
          console.log(err);
          document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333;
          vm.subLatestReasonLoading = false;
        });
      },

      /*
        批量允许出厂
        选中status = 2 状态下的记录出厂
       */
      onClickOutFactoryBatch: function onClickOutFactoryBatch() {
        var vm = this;
        var params = {
          password: vm.$config.base.password,
          operatorName: vm.name,
          factoryCareInfoIds: []
        };
        params.factoryCareInfoIds = vm.checkList.filter(function (item) {
          return item.status = 2;
        }).map(function (item) {
          return item.id;
        });
        vm.outFactoryBatchLoading = true;
        vm.$http({
          type: 'post',
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "probeFacCare/manualAudit"),
          data: params
        }).then(function (res) {
          if (res.code == 1) {
            // 清除所有的确认出厂的记录
            var arr = vm.checkProbeMetaData.filter(function (item) {
              return 2 == item.status;
            });
            arr.forEach(function (item) {
              var dCheckListIndex = -1;
              vm.checkProbeMetaData.forEach(function (metaItem) {
                if (metaItem.id == item.id) {
                  metaItem.id = null;
                  metaItem.status = 4;
                }
              });
              vm.checkList.forEach(function (items, index) {
                items.id == item.id && (dCheckListIndex = index);
              });
              dCheckListIndex > -1 && vm.checkList.splice(dCheckListIndex, 1);
            }); // 关闭弹窗

            vm.outFactoryBatchLoading = false;
            vm.isShowOutFactoryBatch = false;
            vm.filterCheckListArr();
            console.log("操作成功！");
            document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333;
          }
        }).catch(function (err) {
          vm.outFactoryBatchLoading = false;
          vm.isShowOutFactoryBatch = false;
          console.log(err);
          document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333;
        });
      },
      // 点击报废或者出厂按钮,0报废,1出厂
      onClickUpdateOrderStatus: function onClickUpdateOrderStatus(type, item, index) {
        var vm = this;
        vm.selectIndex = index;
        vm.latestData = {
          handlingMethod: type == 0 ? item.responsibleParty == 4 ? '补新' : '报废' : '维护或翻新',
          // iotTagId: item.tagId,
          manualAudit: type == 1 ? 1 : 2,
          responsibleParty: item.responsibleParty,
          id: item.id,
          password: vm.$config.base.password,
          operator: vm.name,
          updateOrderType: type
        };
        vm.isShowLatestReason = true;
      },
      // 报废或者批准出厂选择责任方
      onchangeLastRp: function onchangeLastRp() {
        var vm = this;

        if (vm.latestData.updateOrderType == 0) {
          vm.latestData.handlingMethod = vm.latestData.responsibleParty == 4 ? '补新' : '报废';
        }
      },
      // 查看质检治具详细数据
      viewCalibInfo: function viewCalibInfo(data) {
        var vm = this;

        if (data) {
          vm.passArr = data.content.filter(function (item) {
            return item.state;
          });
          vm.failArr = data.content.filter(function (item) {
            return !item.state;
          });
        }

        vm.isShowCalibModal = true;
      },
      // 获取探头详情
      onclickLoadProbeInfo: function onclickLoadProbeInfo(item, index) {
        var vm = this;
        vm.checkList[index].loading = true;
        vm.$http({
          type: 'get',
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/").concat(item.id)
        }).then(function (res) {
          if (res.data) {
            vm.checkList[index].loading = false;
            vm.checkList[index].cliabData = res.data;
            vm.checkList = [].concat(vm.checkList);
          } else {
            document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333;
          }
        }).catch(function (err) {
          console.log(err);
          document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333;
          vm.checkList[index].loading = false;
        });
      },
      // 切换状态
      onchangeTab: function onchangeTab(index) {
        var vm = this;

        if (index == vm.status) {
          return;
        }

        vm.number = '';
        vm.status = index;
        localStorage.setItem('warningStatusTabIndex', index);
        vm.pageOptions.pageNo = 1;
        vm.list = [];
        vm.loadCheckList();
      },
      onCheckProbeFilteTab: function onCheckProbeFilteTab() {
        var vm = this;
        vm.checkList = vm.filterCheckListArr();
      },
      // type: 0结算归档，1出厂核对
      openUploadModal: function openUploadModal(type) {
        var vm = this;
        vm.uploadType = type;
        vm.isShowUploadModal = true;
      },
      onChange: function onChange(file, files) {
        this.fileList = files;
      },
      onConfirmSelect: function onConfirmSelect() {
        var vm = this;
        vm.isShowSureModal = false;

        _dialog.default.confirm({
          title: "提示",
          message: "\u662F\u5426\u786E\u8BA4\u6240\u6709\u8D23\u4EFB\u65B9\u4E3A".concat(vm.sureResponsibleModel == 5 ? '览宋' : '英沃', "\u7684\u7EF4\u62A4\u5355"),
          confirmButtonColor: "#32BE32"
        }).then(function () {
          vm.ttid = _toast.default.loading({
            forbidClick: true,
            overlay: true,
            duration: 0
          });
          vm.$http({
            type: "POST",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/confirm"),
            data: {
              responsibleParty: vm.sureResponsibleModel,
              password: vm.$config.base.password
            }
          }).then(function () {
            vm.ttid && vm.ttid.clear();

            _toast.default.success({
              message: '已全部确认'
            });

            vm.loadCheckList();
          }).catch(function (err) {
            vm.ttid && vm.ttid.clear();
            vm.loading = false;
            console.log(err);
          });
        }).catch(function () {// on cancel
        });
      },
      onclickSelect: function onclickSelect(model) {
        this.sureResponsibleModel = model;
      },
      modalClose: function modalClose() {
        var vm = this;
        vm.modalStep = 1;
        vm.settlementData = {};
        vm.$refs.upload.clearFiles();
      },
      onSure: function onSure() {
        var vm = this;
        var fd = new FormData();
        fd.append('file', vm.fileList[0].raw);
        vm.loading = true;

        if (vm.uploadType == 1) {
          vm.$http({
            type: "POST",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "probe/outFactoryCheck?password=").concat(vm.$config.base.password),
            data: fd
          }).then(function (res) {
            var data = res.data;
            vm.loading = false;
            vm.checkProbeMetaData = data || [];
            vm.checkList = vm.filterCheckListArr();
            vm.isShowUploadModal = false;
            vm.isShowCheckProbeModal = true;
            vm.$refs.upload.clearFiles();
          }).catch(function (err) {
            vm.loading = false;
            vm.$refs.upload.clearFiles();
            console.log(err);
          });
          return;
        }

        vm.$http({
          type: "POST",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "probeFacCare/settlement?password=").concat(vm.$config.base.password),
          data: fd
        }).then(function (res) {
          var data = res.data;
          vm.loading = false;
          vm.settlementData = data || {};
          vm.modalStep = 2;
          vm.$refs.upload.clearFiles();
        }).catch(function (err) {
          vm.loading = false;
          vm.$refs.upload.clearFiles();
          console.log(err);
        });
      },
      filterCheckListArr: function filterCheckListArr() {
        var vm = this; // 过滤出符合条件的信息

        var arr = vm.checkProbeMetaData.filter(function (item) {
          return Number(vm.checkStatus) > -1 ? item.status == vm.checkStatus : !item.id || item.status > 2 || item.status == 0;
        });
        vm.refreshTabSize();
        return arr || [];
      },
      refreshTabSize: function refreshTabSize() {
        var vm = this;
        vm.$set(vm.tabsSize, 0, vm.checkProbeMetaData.filter(function (item) {
          return !item.id || item.status > 2 || item.status == 0;
        }).length);
        vm.$set(vm.tabsSize, 1, vm.checkProbeMetaData.filter(function (item) {
          return item.status == 1;
        }).length);
        vm.$set(vm.tabsSize, 2, vm.checkProbeMetaData.filter(function (item) {
          return item.status == 2;
        }).length);
      },
      onclickDownloadBtn: function onclickDownloadBtn() {
        var vm = this;

        if (new Date(vm.endDownloadTime).getTime() < new Date(vm.startDownloadTime).getTime()) {
          _toast.default.fail('结束时间必须大于开始时间~');

          return;
        }

        vm.loading = true;
        vm.$http({
          type: "GET",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "factory/careInfo/extra"),
          params: {
            startAt: parseInt(new Date(vm.startDownloadTime).getTime() / 1000),
            endAt: parseInt(new Date(vm.endDownloadTime).getTime() / 1000) + 3600 * 24 - 1,
            statusCode: vm.status
          },
          except: true,
          responseType: 'arraybuffer'
        }).then(function (res) {
          var blob = new Blob([res], {
            type: 'text/plain'
          });
          var filename = (0, _time.getYMD)(new Date(vm.startDownloadTime).getTime(), true) + '__' + (0, _time.getYMD)(new Date(vm.endDownloadTime).getTime(), true);
          var url = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', "\u82F1\u6C83\u7ED3\u7B97\u5355(".concat(filename.split('/').join('-'), ").xls"));
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
          setTimeout(function () {
            vm.loading = false;
            vm.isShowDownloadModal = false;
          }, 1500);
        }).catch(function (err) {
          vm.loading = false;
          console.log(err);
        });
      },
      switchAccount: function switchAccount() {
        var vm = this;

        _dialog.default.confirm({
          title: "提示",
          message: "是否确认退出当前账号？",
          confirmButtonColor: "#32BE32"
        }).then(function () {
          var USER_NAME = vm.$config.keys.USER_NAME;
          vm.$localStorage.removeItem(USER_NAME);
          vm.$router.replace({
            path: "/ryProbe/afterSale/login"
          });
        }).catch(function () {// on cancel
        });
      },
      search: function search() {
        var vm = this;
        var err = '';

        if (vm.number.length < 5) {
          err = '请输入正确的ID';
        }

        if (err) {
          _toast.default.fail({
            message: err
          });

          return;
        }

        vm.loading = true;
        vm.ttid = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        _toast.default.loading;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/search"),
          params: {
            probeId: vm.number
          },
          except: true
        }).then(function (res) {
          vm.loading = false;

          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          vm.ttid && vm.ttid.clear();
          vm.searchProbeList = res.data || [];
          vm.isShowSearchModal = true;
        }).catch(function () {
          vm.ttid && vm.ttid.clear();
        });
      },
      onChangePageNo: function onChangePageNo(pageNo) {
        this.pageOptions.pageNo = pageNo;
        this.loadCheckList();
      },
      onChangePageSize: function onChangePageSize(pageSize) {
        this.pageOptions.pageSize = pageSize;
        this.loadCheckList();
      },
      loadCheckList: function loadCheckList() {
        var vm = this;
        var params = {
          pageNo: vm.pageOptions.pageNo,
          pageSize: vm.pageOptions.pageSize,
          status: vm.status
        };

        if (vm.status == 4 && vm.scrap) {
          params.scrap = true;
        } // 待检查的筛选状态


        if (!vm.status) {
          vm.warningType && (params.tagId = vm.warningType);
        } else {
          Number(vm.probeBatch) > 0 && (params.probeBatch = vm.probeBatch);
          vm.resultTagId && (params.tagId = vm.resultTagId);
          vm.responsiblePartyId && (params.responsibleParty = vm.responsiblePartyId);
        }

        if (vm.startTime && vm.endTime) {
          params.startAt = new Date(vm.startTime).getTime() / 1000;
          params.endAt = new Date(vm.endTime).getTime() / 1000 + 3600 * 24 - 1;
        }

        vm.loading = true;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/list"),
          params: params,
          except: true
        }).then(function (res) {
          vm.loading = false;

          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          res.data.data.map(function (item) {
            var tagName = item.preTagName;

            if (vm.status > 2) {
              tagName = item.tagNameFinal;
            }

            var time = item.createAt;
            vm.status == 1 && (time = item.checkAt);
            vm.status == 2 && (time = item.confirmAt);
            vm.status == 3 && (time = item.settlementAt);
            vm.status == 4 && (time = item.finishAt); // vm.tagList.filter(t => t.id == item.tagId)[0] && (item.tagName = vm.tagList.filter(t => t.id == item.tagId)[0].tagName)

            item.tagName = tagName;
            item.time = (0, _time.getYMD)(time);
            item.warningTagName = tagName;
            item.responsiblePartyText = '英沃';
            item.responsibleParty == 5 && (item.responsiblePartyText = '览宋');
            item.handlingMethodFinalText = (0, _str.filterHandlingMethod)(item.handlingMethod);
            localStorage.setItem('ryProbeAfterSaleListPageNo', vm.pageOptions.pageNo);
          });
          vm.list = [].concat(res.data.data);
          vm.pageOptions.total = res.data.total;

          if (vm.number && !res.data.data.length) {
            vm.isShowModal = true;
            vm.form = {
              probeId: vm.number || '',
              tagId: '',
              remark: ''
            };
          }

          vm.status == 1 && (vm.timeText = '质检时间');
          vm.status == 2 && (vm.timeText = '确认时间');
          vm.status == 3 && (vm.timeText = '结算时间');
          vm.status > 3 && (vm.timeText = '完成时间');
          !vm.status && (vm.timeText = '创建时间');
        }).catch(function () {
          vm.loading = false;
        });
      },
      // type: 2修改，1同意
      handleClick: function handleClick(row, type) {
        this.$refs.updateProbeCheckModal.initData(row, type);
      },
      // 全部确认
      onclickSureBtn: function onclickSureBtn() {
        this.sureResponsibleModel = '';
        this.isShowSureModal = true;
      },
      // 查看维护详情
      viewInfo: function viewInfo(row) {
        var url = this.$router.resolve({
          path: "/ryProbe/afterSale/info/".concat(row.id)
        });
        window.open(url.href, '_blank');
      },
      jumpWarningPage: function jumpWarningPage() {
        this.$router.push({
          path: '/ryProbe/afterSale/warningList'
        });
      },
      onCancel: function onCancel() {
        this.editItem = {};
        this.singleItem = {};
        this.singleItem = {};
        this.isShowModal = false;
        this.createResponsiblePartyObj = {};
      },
      onConfirm: function onConfirm() {
        var vm = this;
        var createResponsiblePartyObj = vm.createResponsiblePartyObj,
            form = vm.form;

        if (createResponsiblePartyObj.responsibleParty && createResponsiblePartyObj.responsibleParty != form.responsibleParty && !form.remark) {
          _toast.default.setDefaultOptions({
            zIndex: 3000
          });

          (0, _toast.default)('请填写认定原因');
          return;
        }

        var liabilityReason = createResponsiblePartyObj.resaon;
        createResponsiblePartyObj.responsibleParty && createResponsiblePartyObj.responsibleParty != form.responsibleParty && (liabilityReason = form.remark);
        vm.loading = true;
        vm.$http({
          type: vm.editItem.id ? 'PUT' : "POST",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo"),
          except: true,
          data: {
            operator: vm.name,
            probeId: vm.form.probeId,
            liabilityReason: liabilityReason,
            tagId: vm.form.tagId,
            password: vm.$config.base.password,
            responsibleParty: vm.form.responsibleParty
          }
        }).then(function (res) {
          vm.loading = false;

          if (res.code != 1) {
            (0, _toast.default)(res.message);
            return;
          }

          vm.loadCheckList();
          vm.onCancel();
        }).catch(function (err) {
          vm.loading = false;
          console.log(err);
        });
      },
      inputChange: function inputChange(type) {
        var vm = this;

        if (type == 'probeId' && vm.form.probeId.length == 8) {
          vm.$http({
            type: "get",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/").concat(vm.form.probeId),
            except: true
          }).then(function (res) {
            var code = res.code,
                data = res.data;

            if (code == 1 && data.status != 2) {
              vm.singleItem = data;
            } else {
              vm.singleItem = {};
            }
          });
        }

        if (type == 'search') {
          !vm.number && vm.loadCheckList();
          return;
        }

        if (type == 'tag') {
          vm.$http({
            type: "get",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/resp"),
            except: true,
            params: {
              probeId: vm.form.probeId,
              tagId: vm.form.tagId
            }
          }).then(function (res) {
            var code = res.code,
                data = res.data,
                message = res.message;

            if (code == 1) {
              if (data && data.responsibleParty) {
                vm.createResponsiblePartyObj = data;
                vm.form.responsibleParty = data.responsibleParty;
                vm.isConfirmPass = data.responsibleParty;
              } else {
                _toast.default.fail({
                  message: '探头ID不存在~'
                });
              }
            } else {
              _toast.default.fail({
                message: message || '网络错误~'
              });
            }
          });
        }

        vm.isConfirmPass = (vm.form.probeId.length == 8 || vm.form.probeId.length == 9) && vm.form.tagId && vm.form.responsibleParty;
      },
      editSingleItem: function editSingleItem() {
        var vm = this;
        vm.editItem = Object.assign(vm.singleItem);
        vm.handleClick(vm.singleItem);
      }
    }
  };
  _exports.default = _default;
});